import React from "react";
import OktaAuth from "@okta/okta-auth-js";
import { withRouter, RouteComponentProps } from "react-router";

import { AuthConfig } from "@okta/okta-react";
import ProSessionComponent from "./ProSessionComponent";

interface TokenLoginState {
  isTokenActivated: boolean;
}

interface TokenLoginParams {
  token: string;
}

type TokenLoginProps = RouteComponentProps<TokenLoginParams> & {
  auth: ProSessionComponent;
  authClient: OktaAuth;
  authConfig: AuthConfig;
};

export default withRouter(
  class TokenLogin extends React.Component<TokenLoginProps, TokenLoginState> {
    constructor(props: Readonly<TokenLoginProps>, context: any) {
      super(props, context);

      if (!this.props.authClient) {
        throw Error("Internal Error: AuthClient is not set");
      }

      this.loginWithToken = this.loginWithToken.bind(this);
      this.state = {
        isTokenActivated: false,
      };
    }

    async loginWithToken() {
      try {
        const result = await this.props.authClient.signIn({
          token: this.props.match.params.token,
        });

        console.log(result);
        this.props.authClient.token.getWithRedirect({
          prompt: "none",
          responseMode: "fragment",
          responseType: "code",

          scopes: ["openid", "email", "profile"],
          sessionToken: result.sessionToken,
        });

        // resolution only happens after redirect
        return new Promise((resolve, reject) => {});
      } catch (e) {
        console.log(e);
        // token is likely invalid, show the login
        this.props.history.push("/login");
      }
    }

    async componentDidMount() {
      this.loginWithToken();
    }
    async componentDidUpdate() {
      this.loginWithToken();
    }

    render() {
      if (!this.state.isTokenActivated) return null;
      return;

      // return this.state.isTokenActivated ?
      //   <Redirect to={{ pathname: '/' }}/> :
      //   <Redirect to={{ pathname: '/login' }}/>
    }
  }
);
