
import React from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn, {SigninAction} from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import './OktaSigninWidget.scss';

interface OktaSigninWidgetProps {
  oktaWidget: OktaSignIn;
  onSuccess?: (result: SigninAction) => void;
  onError?: (error: any) => void;
}

function expandAccordion() {
  // Automatically expand the accordion help
  setTimeout(() => {
    const authFooterLink = document.querySelector('.auth-footer .js-help');
    if (authFooterLink) (authFooterLink as any).click();
  }, 20);
}

function watchForTransition() {
  setTimeout(() => {
    const linkToSignUp = document.querySelector('[aria-label="Sign up"]')
    if (linkToSignUp) {
      linkToSignUp.addEventListener('click', () => {
        setTimeout(() => {
          const linkToSignIn = document.querySelector('.link.help')
          if (linkToSignIn) {
            linkToSignIn.addEventListener('click', () => {
              expandAccordion()
              watchForTransition()
            })
          }
        }, 500)
      })
    }
  }, 20)
}

export default class OktaSignInWidget extends React.Component<OktaSigninWidgetProps> {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    this.props.oktaWidget.renderEl({el}, this.props.onSuccess, this.props.onError);
    expandAccordion()
    watchForTransition()
  }

  componentWillUnmount() {
    this.props.oktaWidget.remove();
  }

  render() {
    return <div className="devacurl-pro-login" />;
  }
};
