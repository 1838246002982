import { createBrowserHistory, Location, History } from "history";
import * as React from "react";
import { ReactChildren } from "react";
import { Redirect, Route, Router } from "react-router";
import * as QueryString from "query-string";

import OktaSignIn, {
  OktaSession,
} from "@okta/okta-signin-widget/dist/js/okta-sign-in.min.js";
import OktaAuth from "@okta/okta-auth-js";
import { AuthConfig, Security } from "@okta/okta-react";

import Login from "./Login";
import Logout from "./Logout";
import TokenLogin from "./TokenLogin";
import ProSessionContext from "./ProSessionContext";

const OKTA_BASE_URL = process.env.REACT_APP_OKTA_BASE_URL as string;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID as string;
const OKTA_ISSUER_ID = process.env.REACT_APP_OKTA_ISSUER_ID || "default";

interface ProUser {}

export interface ProSessionState {
  authenticated?: boolean;
  children: ReactChildren;
  redirectUri?: string;
}

export interface ProSessionProps {
  history?: History;
  user?: ProUser;
  oktaWidget?: OktaSignIn;
}

export default class ProSessionComponent extends React.Component<
  ProSessionProps,
  ProSessionState
> {
  /**
   * There are two different APIs used to manage user credentials and
   * communicate with the API: the authClient (OktaAuth) is the more raw version.
   * Generally providing more direct access, but does not offer a way to store
   * tokens, process a oauth request, or interpret parameters in the URL. For those,
   * the "authManager" provides those: (named Auth, in the okta-react package).
   */
  private readonly authClient: OktaAuth;

  private history!: History;
  private authConfig!: AuthConfig;
  private oktaWidget!: OktaSignIn;

  constructor(props: Readonly<ProSessionProps>) {
    super(props);

    this.authConfig = {
      clientId: OKTA_CLIENT_ID,
      issuer: OKTA_BASE_URL + "oauth2/" + OKTA_ISSUER_ID,
      onAuthRequired: () => {
        const history = this.history;
        if (history.location.pathname !== "/login") {
          history.push("/login");
        }
      },
      redirectUri: window.location.origin + "/implicit/callback",
      pkce: true,
    };

    this.authClient = new OktaAuth({
      ...this.authConfig,
      url: OKTA_BASE_URL,
    });

    this.state = this.getUpdatedStateFromProps();
    this.login = this.login.bind(this);
  }

  handleLogin(session?: OktaSession) {
    if (session) {
      if (this.state.redirectUri) {
        session.setCookieAndRedirect(this.state.redirectUri);
      } else {
        session.setCookieAndRedirect("https://www.devacurlpro.com/education");
      }
    }
  }

  async logout() {
    try {
      await this.authClient.signOut();
    } catch (err) {}

    this.history.push("/login");
  }

  private getUpdatedStateFromProps() {
    let state = { ...this.state };

    let history = this.props.history || createBrowserHistory();
    if (this.history !== history) {
      this.history = history;
      this.history.listen((location) => this.updateRedirectUri(location));

      state.redirectUri = this.findRedirectUri(this.history.location);
    }

    const oktaWidget = this.props.oktaWidget || this.createOktaWidget();
    if (this.props.oktaWidget !== oktaWidget) {
      this.oktaWidget = oktaWidget;
    }

    return state;
  }

  private createOktaWidget(): OktaSignIn {
    return new OktaSignIn({
      baseUrl: OKTA_BASE_URL,
      authParams: this.authConfig,
      features: {
        registration: true,
      },
      helpLinks: {
        help: "https://www.devacurl.com/us/pro-account-help",
        custom: [
          {
            text: "Having issues Registering? Email devacurlpro@devacurl.com",
            href: "mailto:devacurlpro@devacurl.com",
          },
        ],
      },
      i18n: {
        en: {
          // Overriding English properties
          "primaryauth.username.placeholder": "Email",
          "primaryauth.username.tooltip": "Email",
          "account.unlock.email.or.username.placeholder": "Enter {0} Username",
          "error.username.required": "Please enter your email",
        },
      },
    });
  }

  private updateRedirectUri(location: Location) {
    const uri = this.findRedirectUri(location);
    if (uri === this.state.redirectUri) {
      return;
    }

    this.setState({
      redirectUri: uri,
      ...this.state,
    });
  }

  private findRedirectUri(location: Location): string | undefined {
    const query = QueryString.parse(location.search);
    if ("fromURI" in query && typeof query["fromURI"] === "string") {
      return query["fromURI"] as string;
    } else {
      return undefined;
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ProSessionProps>,
    prevState: Readonly<ProSessionState>,
    snapshot?: any
  ): void {
    this.authConfig.redirectUri = this.state.redirectUri;
  }

  async login() {
    const authenticated = await this.isAuthenticated();
    if (!authenticated) {
      this.history.push("/login");
    }
  }

  async isAuthenticated(): Promise<boolean> {
    if (typeof this.state.authenticated === "boolean") {
      return this.state.authenticated;
    }

    try {
      const session = await this.authClient.session.get();
      // there may be a session on OKTA even if we're not awaare of it
      if (session) {
        if (session.status === "ACTIVE") {
          this.setState({
            ...this.state,
            authenticated: true,
          });
          this.handleLogin();
          // this.loadUser();
          return true;
        }
      }
    } catch (err) {}

    this.setState({
      ...this.state,
      authenticated: false,
    });
    return false;
  }

  render() {
    const securityContext = {
      auth: this,
    };

    return (
      <Router history={this.history}>
        <Security auth={this}>
          <ProSessionContext.Provider value={securityContext}>
            <Route path="/implicit/callback">
              <Redirect to={{ pathname: "/" }} />
            </Route>
            <Route
              path="/login"
              render={() => <Login oktaWidget={this.oktaWidget} />}
            />
            <Route
              path="/logout"
              exact={true}
              component={Logout}
              authClient={this.authClient}
            />
            <Route
              path="/token/:token"
              render={() => (
                <TokenLogin
                  auth={this}
                  authClient={this.authClient}
                  authConfig={this.authConfig}
                />
              )}
            />
            {this.props.children}
          </ProSessionContext.Provider>
        </Security>
      </Router>
    );
  }
}
