import { Component } from "react";

import { SecureComponentProps, withAuth } from "@okta/okta-react";
import { RouteComponentProps } from "react-router";
// import qs from 'querystring'

import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

import "./Dashboard.scss";
// import OktaAuth from "@okta/okta-auth-js";

type DashboardProps = RouteComponentProps & SecureComponentProps;

export default withAuth(
  class Dashboard extends Component<DashboardProps> {
    constructor(props?: any, context?: any) {
      super(props, context);
    }

    componentWillUnmount() {
      // <div className="card" style="width: 18rem;">
      //   <img className="card-img-top" src="..." alt="Card image cap">
      //   <div className="card-body">
      //     <h5 className="card-title">Card title</h5>
      //     <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
      //     <a href="#" className="btn btn-primary">Go somewhere</a>
      //   </div>
      // </div>
    }

    render() {
      window.location.href = "https://www.devacurlpro.com/education";
      return null;

      // return <div className="devacurl-pro-dashboard container">
      //   <div className="devacurl-academy-intro row">
      //     <div className="col-xs-3">
      //       <svg xmlns="http://www.w3.org/2000/svg"
      //            xmlnsXlink="http://www.w3.org/1999/xlink"
      //            className="svg svg--black" role="img" aria-labelledby="title-devacurl-academy-logo"
      //            aria-hidden="false" width="178" height="44" id="title-devacurl-academy-logo">
      //         <title>DevaCurl Academy</title>
      //         <use xlinkHref="#devacurl-academy-logo"/>
      //       </svg>
      //     </div>
      //     <div className="col-sm-9">
      //       <p>Whether you’re looking to take a class online, near you, or at our NYC Academy, we have
      //         education options for everyone. Explore our range of class offerings and find the curl
      //         career journey that’s right for you.</p>
      //     </div>
      //   </div>
      //   <div className="row card-group">
      //     <div className="col col-sm-6 col-md-3">
      //       <div className="card-contents">
      //         <div className="card-header">
      //           <h6>Free online class</h6>
      //           <img
      //             src="https://res.cloudinary.com/devacurl/image/upload/f_auto,q_auto,w_500,c_fill,d_true,ar_1:1,dpr_auto/SiteAssets/bottles"/>
      //         </div>
      //         <div className="card-body">
      //           <h3>Product Pro</h3>
      //           <h6>product expert</h6>
      //           <button>Getting Started</button>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="col col-sm-6 col-md-3">
      //       <div className="card-contents">
      //         <div className="card-header">
      //           <h6>NYC Academy / Field Class</h6>
      //           <img
      //             src="https://res.cloudinary.com/devacurl/image/upload/f_auto,q_auto,w_500,c_fill,d_true,ar_1:1,dpr_auto/SiteAssets/hair_cut_image"/>

      //         </div>
      //         <div className="card-body">
      //           <h3>Level 1 Pro</h3>
      //           <h6>deva inspired stylist</h6>
      //           <button>Getting Started</button>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="col col-sm-6 col-md-3">
      //       <div className="card-contents">
      //         <div className="card-header">
      //       <h6>NYC Academy</h6>
      //       <img
      //         src="https://res.cloudinary.com/devacurl/image/upload/f_auto,q_auto,w_500,c_fill,d_true,ar_1:1,dpr_auto/SiteAssets/salon_image"/>
      //         </div>
      //         <div className="card-body">
      //           <h3>Level 2 Advanced</h3>
      //           <h6>deva advanced stylist</h6>
      //           <button>Getting Started</button>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="col col-sm-6 col-md-3">
      //       <div className="card-contents">
      //         <div className="card-header">
      //       <h6>NYC Academy</h6>
      //       <img
      //         src="https://res.cloudinary.com/devacurl/image/upload/f_auto,q_auto,w_500,c_fill,d_true,ar_1:1,dpr_auto/SiteAssets/salon_image_2"/>
      //       <h3>Level 3 Master</h3>
      //       </div>
      //       <div className="card-body">
      //         <h6>deva certified stylist</h6>
      //         <button>Getting Started</button>
      //       </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    }
  }
);
