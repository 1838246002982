
import * as React from "react";
import ProSessionComponent from "./ProSessionComponent";

export interface ProSessionContextValue {
  auth?: ProSessionComponent,
}

const ProSessionContext = React.createContext<ProSessionContextValue>({});
export default ProSessionContext;
