
import React from 'react';
import { SecureRoute } from '@okta/okta-react';

import ProSessionComponent from "./frontend/pro/account/ProSessionComponent";
import Dashboard from './frontend/pro/Dashboard';

import './frontend/pro/theme';
import './App.scss'

interface AppState {
  redirectUri: string,
}


class App extends React.Component<{}, AppState> {
  render() {
    return (
      <ProSessionComponent>
        <SecureRoute path='/' exact={true} component={Dashboard} />
      </ProSessionComponent>
    );
  }

}
export default App;
