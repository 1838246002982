
import React, { Component } from 'react';
import {Redirect} from "react-router";

import OktaSignInWidget from "./OktaSignInWidget";
import OktaSignIn, {SigninAction} from "@okta/okta-signin-widget";

import ProSessionContext from "./ProSessionContext";
import './Login.scss'

interface LoginProps {
  oktaWidget: OktaSignIn,
}
interface LoginState {
  authenticated: boolean | null,
}


export default class Login extends Component<LoginProps, LoginState> {
  static contexxtType = ProSessionContext;
  context!: React.ContextType<typeof ProSessionContext>;

  constructor(props: LoginProps) {
    super(props);

    this.state = {
      authenticated: null
    };
  }

  componentWillMount(): void {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    if (!this.context || !("auth" in this.context) || typeof this.context.auth !== "object") {
      return;
    }

    let authenticated = await this.context.auth.isAuthenticated();
    this.setState({
      ...this.state,
      authenticated: authenticated,
    })
  }

  onSuccess(res: SigninAction) {
    if (!this.context || !("auth" in this.context) || typeof this.context.auth !== "object") {
      return;
    }
    if (res.status === 'SUCCESS' && "session" in res) {
      this.context.auth.handleLogin(res.session);
      // this.context.auth.login(this.props.redirectUri);
   } else {
    // The user can be in another authentication state that requires further action.
    // For more information about these states, see:
    //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  }

  onError(err: any) {
  }

  render() {
    if (this.state.authenticated === null) return null;

    return this.state.authenticated ?
      <Redirect to={{ pathname: '/' }}/> :
      <>
        <div className="having-issues">
          <p>If you haven’t signed in since Oct 2019 <a href="#" title="go to sign in" onClick={goToSignUp}>Re-register Here</a> using the same email from your old account</p>
          {/* <p>Still having issues registering or signing in? Email <a href="mailto:devacurlpro@devacurl.com" title="go to sign in">devacurlpro@devacurl.com</a></p> */}
        </div>
        <OktaSignInWidget
          oktaWidget={this.props.oktaWidget}
          onSuccess={(res: SigninAction) => this.onSuccess(res)}
          onError={this.onError}/>
      </>;
  }
};

Login.contextType = ProSessionContext;

// Simulate click on fake signup page
function goToSignUp(e: Parameters<React.ReactEventHandler>[0]) {
  e.preventDefault()
  const link = document.querySelector('.registration-link')
  if (link) {
    (link as HTMLElement).click()
  }
}