
import React from 'react';
import { withRouter, RouteComponentProps } from "react-router";
import { withAuth, SecureComponentProps } from '@okta/okta-react';

type LogoutProps = RouteComponentProps & SecureComponentProps;

export default withAuth(withRouter(class Logout extends React.Component<LogoutProps> {
  constructor(props: LogoutProps) {
    super(props);
    // this.onSuccess = this.onSuccess.bind(this);
    // this.onError = this.onError.bind(this);
    this.state = {}
  }

  render() {
    if (!this.props.auth || typeof this.props.auth.logout !== "function") {
      return;
    }

    this.props.auth.logout('/login');

    return null;
  }
}));
